import { Affix, Divider, Layout, Typography, Spin, Card, Button, Row, Col, Breadcrumb, Space, Anchor } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Contents from "./Contents";
import Html from "../utils/Html";
import conditional from "../utils/conditional";
import moment from "moment";
import { trimLastSlash, ucfirst } from "../utils/Utils";
import Home from "./Home";
import { CaretLeftFilled, LeftOutlined, MenuOutlined } from '@ant-design/icons';
export default function BasicPage(props) {


  

    const location = useLocation();

    const location_pathname = trimLastSlash(location.pathname);
    const ref = useRef();
    const { results = [], section = "", canEdit = false, drawBackButton = () => { } } = props;
    const current = results.find(item => "/" + section + "/" + item.path === location_pathname) || { ID: 0, heading: "", slug: section, type: section, details: "", date: "", path: "/" + section, parent: 0 }
    const [height, setHeight] = useState(100);
    const peers = results.filter(item => item.parent === current.parent && item.type === section);
    const children = results.filter(item => item.parent === current.ID && item.type === section);
    const level = (location_pathname.split("/").length);
    const anchors = (level > 4 && children.length > 0) ? children : [];

    const navigate = useNavigate();

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])


    const shiftTo = (_hash) => {
        let element = document.getElementById(_hash);
        if (!element) {
            return;
        }
        var headerOffset = 80;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    const getAdminLink = () => {
        if (canEdit) {
            return (<div style={{ "float": "right" }}><Button onClick={() => window.open("/wp-admin/post.php?post=" + current.ID + "&action=edit")} type="primary" size="small"><small>Edit Page</small></Button></div>)
        }
    }

    const getHeight = () => {
        setTimeout(() => {
            if (window.innerWidth < 600) {
                return;
            }
            let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            let h = ref.current.clientHeight || 0;
            if (h > vh) {
                setHeight(-(h - vh))
            } else {
                setHeight(105)
            }
        }, 500)
    }

    useEffect(getHeight, [location.pathname])

    const drawPath = (_p) => {
        _p = _p.replace("#", "/");
        if (_p.includes("/filter")) {
            _p = _p.split("/filter")[0];
        }
        let items = _p.split("/");
        if (!_p.length > 0) {
            return (<></>);
        }
        let m = [];
        return items.map((itm, ind) => {
            m.push(itm);
            let x = [...m];
            if (itm === "") {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate("/")}>Home</a></Breadcrumb.Item>
            } else {
                return <Breadcrumb.Item key={ind}><a style={{ "cursor": "pointer" }} onClick={() => navigate(x.join("/"))}>{ucfirst(itm)}</a></Breadcrumb.Item>
            }
        })
    }

    const drawItem = (_item) => {
        return (
            <div id={_item.slug}>
                <Typography.Title level={4}>
                    <Row>
                        <Col flex="auto">{_item.heading}</Col>
                    </Row>
                </Typography.Title>
                <div style={{ "marginTop": "-10px" }}><em style={{ "opacity": "0.6" }}>Last updated on {moment(_item.date).format("MMM Do, YYYY")}</em></div>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <Html html={_item.details} />
                <br /><br />
            </div>
        )
    }

    const drawContent = () => {
        return (
            <div style={{ "maxWidth": (children.length > 0) ? "1000px" : "1200px", "display": "flex" }}>
                <div style={{ "flex": "1" }}>
                    {(current.details &&
                        <div>
                            {getAdminLink()}
                            {drawItem(current)}
                        </div>
                    )}
                    {((!current.details && children.length > 0) &&
                        <div>
                            {getAdminLink()}
                            {drawItem(children[0])}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const AffixWrapper = (props) => {
        return (
            <div>
                <Affix className="show" offsetTop={height - 34}>
                    <div ref={ref} className="page" style={{ "minHeight": "calc(100vh - 70px)" }}>
                        {props.children}
                    </div>
                </Affix>
                <div className="hide">
                    <div className="page" style={{ "minHeight": "calc(100vh - 70px)" }}>
                        {props.children}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Layout style={{ "padding": "0px" }} className="layout">
                {(section !== "newsletter" && section !=="updates" && <Contents shiftTo={shiftTo} anchors={anchors} peers={peers} current={current} data={results} section={section} />)}
                <Layout.Content>
                    <AffixWrapper>
                        <div className="pageOffset" style={{ "marginLeft": "-15px" }}>
                            <div style={{ "float": "right" }}>{((section === "updates" || section === "newsletter") && <Button onClick={() => navigate("/" + section)} size="medium" type="ghost"><LeftOutlined />&nbsp; Back</Button>)}</div>
                            <Breadcrumb style={{ "fontSize": "12px", "fontWeight": "500", "padding": "5px 15px" }}>
                                {drawPath(location.pathname)}
                            </Breadcrumb>
                            <div className="shim" /><div className="shim" /><div className="shim" /><div className="shim" />
                        </div>
                        {drawContent()}
                    </AffixWrapper>
                </Layout.Content>
            </Layout>
        </>
    )
}